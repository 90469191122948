var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "options_alert" },
    [
      _c(
        "van-dialog",
        {
          attrs: { "show-cancel-button": "", "before-close": _vm.beforeClose },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.caseAlert.icon === 1
                    ? "新建文件夹"
                    : _vm.caseAlert.icon === 2
                    ? "重命名"
                    : ""
                ) +
                " "
            ),
          ]),
          _c("van-field", {
            attrs: { placeholder: "请输入..." },
            model: {
              value: _vm.username,
              callback: function ($$v) {
                _vm.username = $$v
              },
              expression: "username",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }