var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.isSearch ? "cell_container_search" : "cell_container" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "van-cell-group",
            _vm._l(_vm.calcAnjiandangan, function (item, index) {
              return _c(
                "van-cell",
                {
                  key: index,
                  attrs: {
                    icon: item.leftIcon === "FILE" ? "pending-payment" : "",
                    title: item.name.toString(),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getType(item)
                    },
                  },
                },
                [
                  _c("van-icon", {
                    staticClass: "van-cell__right-icon",
                    attrs: {
                      slot: "right-icon",
                      name: item.rightIcon ? "edit" : "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.showShadown($event, item)
                      },
                    },
                    slot: "right-icon",
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c("option-component", {
            attrs: { position: _vm.position, result: _vm.result },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }