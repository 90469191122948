var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "anjian_layout" },
    [
      _c("search"),
      _c("breadcrumb", { on: { resetHistory: _vm.resetHistory } }),
      _c("content-cell", {
        attrs: { historyCase: _vm.historyCase },
        on: { resetHistory: _vm.resetHistory },
      }),
      _c("global-alert"),
      _c("Tag"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }