var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumb_container" },
    [
      _c("van-icon", {
        attrs: { name: "search" },
        on: { click: _vm.setIsSearch },
      }),
      _c(
        "ul",
        {
          on: {
            touchend: function ($event) {
              $event.stopPropagation()
              return (() => {}).apply(null, arguments)
            },
          },
        },
        _vm._l(_vm.history, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              style: { color: item.active ? "#26a2ff" : "#333" },
              on: {
                click: function ($event) {
                  !item.active && _vm.selectTag(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }