<template>
  <div class="shadown_container" @click="isShadow = false" v-show="isShadow">
    <div class="shadow_body" ref="shadow_body" :style="styles">
      <van-cell-group>
        <van-cell
          v-for="(item, index) of result.tags"
          :key="index"
          :title="item.name"
          @click="selectTag(result, item)"
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import { caseBase, qiniuUpload } from '~api-config'
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  props: {
    position: {
      type: Object,
      default: () => {
        return {}
      }
    },
    result: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isShadow: false,
      styles: {}
    }
  },
  watch: {
    position(obj) {
      this.isShadow = true
      this.$nextTick(() => {
        const width = this.$refs.shadow_body.offsetWidth
        this.styles = {
          top: obj.y - 30 + 'px',
          left: obj.x - width + 30 + 'px'
        }
      })
    }
  },
  computed: {
    ...mapState('zhishishu', ['history', 'userId'])
  },
  methods: {
    ...mapMutations('zhishishu', ['setCaseAlert']),
    ...mapActions('zhishishu', ['getFindCases']),
    async selectTag(result, item) {
      // const userId = this.$store.state.user.userInfo.id·`
      // 新建文件夹
      if (item.icon === 1) {
        this.setCaseAlert({ show: true, ...result, ...item })
      }
      // 重命名
      if (item.icon === 2) {
        this.setCaseAlert({ show: true, ...result, ...item })
      }
      // 删除
      if (item.icon === 3) {
        this.$dialog
          .confirm({
            title: '提示',
            message: '删除不可恢复！确定删除吗？'
          })
          .then(async () => {
            // on confirm
            const response = await this.$axios.delete(
              `${caseBase}/design/fileManage/delete/${result.id}`
            )
            const { code } = response.data
            if (code === 200) {
              this.$toast.success('删除成功')
              this.getFindCases(this.history[this.history.length - 1])
            }
          })
          .catch(() => {
            // on cancel
          })
      }
      // 上传
      if (item.icon === 4) {
        const link = document.createElement('input')
        link.style.display = 'none'
        link.setAttribute('type', 'file')
        document.body.appendChild(link)
        link.click()
        link.addEventListener('change', e => {
          this.$axios.get(`${caseBase}/design/file/token`).then(response => {
            const file = e.target.files[0]
            const formData = new FormData()
            formData.append('file', file)
            formData.append('token', response.data.data.token)
            this.$axios
              .post(qiniuUpload, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(response => {
                // 拼接完整的图片的地址
                const params = {
                  caseId: this.$route.params.caseId || null,
                  creater: this.userId,
                  fileName: file.name,
                  filePath: response.data.key,
                  isDir: 0,
                  pId: result.pId
                }
                this.$axios
                  .post(`${this.$base}/design/fileManage/add`, params)
                  .then(response => {
                    this.$toast.success('上传成功')
                    this.getFindCases(this.history[this.history.length - 1])
                  })
              })
          })
        })
      }
    }
  }
}
</script>

<style lang="stylus">
.shadown_container
  background rgba(0, 0, 0, 0.3)
  height 100%
  width 100vw
  position fixed
  top 0
  left 0
  z-index 100
  & > .shadow_body
    position fixed
    &::before
      content ''
      border-width 0 8px 10px
      border-style solid
      border-color transparent transparent #fff /* 透明 透明  灰 */
      position absolute
      top -6px
      right 5px
  & .van-cell-group
    border-radius 5px
    overflow hidden
</style>
