var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.caseTag.length !== 0,
          expression: "caseTag.length !== 0",
        },
      ],
      staticClass: "laout_tag_container",
    },
    _vm._l(_vm.caseTag, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          style: { background: _vm.color[index] },
          on: {
            click: function ($event) {
              return _vm.clickHandel(item, index)
            },
          },
        },
        [
          _vm._v(" " + _vm._s(item.name.substr(0, 1)) + " "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show === index,
                  expression: "show === index",
                },
              ],
              staticClass: "show_tag",
            },
            [
              _c("van-cell", {
                attrs: { title: "客户案件", icon: "wap-nav" },
                on: {
                  click: function ($event) {
                    return _vm.jumpCase(item)
                  },
                },
              }),
              _c("van-cell", {
                attrs: { title: "拨打电话", icon: "phone" },
                on: {
                  click: function ($event) {
                    return _vm.callPhone(item.phone)
                  },
                },
              }),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }