<template>
  <div class="options_alert">
    <van-dialog
      v-model="show"
      show-cancel-button
      :before-close="beforeClose"
    >
      <p>
        {{
        caseAlert.icon === 1 ? '新建文件夹' : caseAlert.icon === 2 ? '重命名' :''
        }}
      </p>
      <van-field
        v-model="username"
        placeholder="请输入..."
      />
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { caseBase } from '~api-config'
export default {
  data () {
    return {
      username: ''
    }
  },
  computed: {
    ...mapState('zhishishu', ['caseAlert', 'userId', 'history']),
    show: {
      get () {
        return this.caseAlert.show
      },
      set (value) {
        this.setCaseAlert({ ...this.caseAlert, show: value })
      }
    }
  },
  watch: {
    caseAlert (value) {
      if (value.icon === 2) {
        this.username = value.fileName.split('.')[0]
      } else {
        this.username = ''
      }
    }
  },
  methods: {
    ...mapMutations('zhishishu', ['setCaseAlert']),
    ...mapActions('zhishishu', ['getFindCases']),
    async beforeClose (action, done) {
      if (action === 'confirm') {
        // 新建文件夹
        const type = this.caseAlert.icon
        if (type === 1 && !this.isNull(this.username)) {
          const data = {
            caseId: this.$route.params.caseId || null,
            creater: this.userId,
            fileName: this.username,
            isDir: 1,
            pId: this.caseAlert.pId
          }
          const response = await this.$axios.post(`${caseBase}/design/fileManage/add`, data)
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('新增成功')
            this.getFindCases(this.history[this.history.length - 1])
            done()
          }
        } else if (type === 2 && !this.isNull(this.username)) {
          const response = await this.$axios.put(`${caseBase}/design/fileManage/update?fileName=${this.username}&id=${this.caseAlert.id}`)
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('重命名成功')
            this.getFindCases(this.history[this.history.length - 1])
          }
        } else {
          this.$toast.fail('无效字符')
          done()
        }
      } else {
        done()
      }
    },
    isNull (str) {
      if (str === '') {
        return true
      }
      var regu = '^[ ]+$'
      var re = new RegExp(regu)
      return re.test(str)
    }
  }
}
</script>

<style lang="stylus">
.options_alert {
  & p {
    text-align: center;
    padding: 10px;
  }
}
</style>
