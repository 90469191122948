<template>
  <div :class="isSearch ? 'cell_container_search' : 'cell_container'">
    <van-list v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad">
      <van-cell-group>
        <van-cell v-for="(item, index) of calcAnjiandangan"
                  :key="index"
                  :icon="item.leftIcon === 'FILE' ? 'pending-payment' : ''"
                  :title="item.name.toString()"
                  @click="getType(item)">
          <van-icon slot="right-icon"
                    :name="item.rightIcon ? 'edit' : ''"
                    @click.stop="showShadown($event,item)"
                    class="van-cell__right-icon" />
        </van-cell>
      </van-cell-group>
      <option-component :position="position"
                        :result="result" />
    </van-list>
  </div>
</template>

<script>
import OptionComponent from './option'
import { caseBase, qiniuBase } from '~api-config'
import { ImagePreview } from 'vant'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  components: {
    OptionComponent
  },
  props: ['historyCase'],
  data () {
    return {
      position: {},
      result: {},
      iframeUrl: '',
      finished: false,
      loading: false,
      showIndex: 10
    }
  },
  computed: {
    ...mapState('zhishishu', ['isSearch', 'anjiandangan']),
    calcAnjiandangan () {
      return this.anjiandangan.filter((elemnet, index) => index < this.showIndex)
    }
  },
  watch: {
    historyCase () {
      this.finished = this.historyCase.finished
      this.loading = this.historyCase.loading
      this.showIndex = this.historyCase.showIndex
      this.onLoad()
    }
  },
  methods: {
    ...mapMutations('zhishishu', ['setHistory', 'setAnjiandangan']),
    ...mapActions('zhishishu', ['getFindCases']),
    showShadown (event, item) {
      // console.log()
      // let x = event.clientX - event.offsetX
      // let y = event.clientY - event.offsetY
      const headerHeight = document.querySelector('.fb-header') ? document.querySelector('.fb-header').clientHeight : 0
      const { y } = event.target.getBoundingClientRect()
      const scrollTop = document.querySelectorAll('.dynamic-height-tab.van-tab__pane')[5] ? document.querySelectorAll('.dynamic-height-tab.van-tab__pane')[5].scrollTop : 0
      const __translateY__ = window.__translateY__ ? window.__translateY__ : 0
      const tabHeight = (document.querySelector('.van-tabs--line .van-tabs__wrap') && this.$route.name === 'search') ? document.querySelector('.van-tabs--line .van-tabs__wrap').offsetHeight : 0
      this.position = { x: event.clientX, y: y + scrollTop - headerHeight - __translateY__ + tabHeight }
      // console.log({ x: event.clientX, y: event.clientY })
      this.result = item
    },
    onLoad () {
      setTimeout(() => {
        this.showIndex += 10
        this.loading = false
        if (this.anjiandangan.length <= this.showIndex) {
          this.finished = true
        }
      }, 300)
    },
    async getType (item) {
      // console.log(item)
      this.$emit('resetHistory')
      if (item.filePath) {
        // 判断是不是图片
        if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.name)) {
          let index = 0
          const dataImg = this.anjiandangan
            .filter(item => /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.name))
            .map((file, i) => {
              if (file.filePath === item.filePath) {
                index = i
              }
              if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.name)) {
                return `${qiniuBase}/${file.filePath}`
              }
            })
          ImagePreview({
            images: [...dataImg],
            startPosition: index
          })
          // 文书
        } else {
          this.$router.push({
            name: 'preview',
            params: {
              url: `http://view.officeapps.live.com/op/embed.aspx?src=http:${qiniuBase}/${item.filePath}&amp;wdStartOn=1`
            }
          })
        }
      } else {
        if (item.types === 5) {
          this.$router.push('/editor?caseDocumentId=' + item.id)
        }
        if (item.fileName === '文书') {
          const response = await this.$axios.get(`${caseBase}/new-design/catalog/list?style=1&caseId=${item.id}`)
          const { catalogs } = response.data.data
          const data = catalogs.map(item => {
            return {
              ...item,
              leftIcon: 'FILE',
              rightIcon: false,
              types: 5
            }
          })
          console.log(data)
          // if (data.length === 0) {
          this.setHistory({
            data: {
              id: -1,
              name: '>文书',
              active: true
            }
          })
          // }
          this.setAnjiandangan(data)
        } else {
          this.selectCell(item)
          this.getFindCases(item)
        }
      }
    },
    // 点击添加面包屑
    selectCell ({ id, name, types, ...item }) {
      const obj = {
        id,
        name: '>' + name,
        active: true,
        types,
        ...item
      }
      this.setHistory({ data: obj })
    }
  }
}
</script>

<style lang="stylus">
.cell_container
  // height calc(100vh - 176px + 44px)
  // overflow-x hidden
  // overflow-y auto
  // padding-bottom 300px
  & .van-cell__title, .van-cell__value
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
.cell_container_search
  // height calc(100vh - 176px)
  // overflow-x hidden
  // overflow-y auto
  // padding-bottom 300px
  & .van-cell__title, .van-cell__value
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
</style>
