var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShadow,
          expression: "isShadow",
        },
      ],
      staticClass: "shadown_container",
      on: {
        click: function ($event) {
          _vm.isShadow = false
        },
      },
    },
    [
      _c(
        "div",
        { ref: "shadow_body", staticClass: "shadow_body", style: _vm.styles },
        [
          _c(
            "van-cell-group",
            _vm._l(_vm.result.tags, function (item, index) {
              return _c("van-cell", {
                key: index,
                attrs: { title: item.name },
                on: {
                  click: function ($event) {
                    return _vm.selectTag(_vm.result, item)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }