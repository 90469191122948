<template>
  <div class="breadcrumb_container">
    <van-icon name="search"
              @click="setIsSearch" />
    <ul @touchend.stop="() => {}">
      <li v-for="(item,index) of history"
          @click="!item.active && selectTag(item)"
          :style="{color:item.active ? '#26a2ff' : '#333'}"
          :key="index">
        {{item.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('zhishishu', ['history'])
  },
  methods: {
    ...mapMutations('zhishishu', ['setIsSearch', 'setHistory']),
    ...mapActions('zhishishu', ['getFindCases']),
    selectTag (item) {
      this.$emit('resetHistory')
      this.getFindCases(item)
    }
  },
  watch: {
    history () {
      setTimeout(() => {
        document.querySelector('.breadcrumb_container ul').scrollLeft = 30000
      }, 100)
    }
  },
  destroyed () {
    this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })
  }
}
</script>

<style lang="stylus">
.breadcrumb_container
  height 44px
  display flex
  align-items center
  margin 0 15px 0 10px
  & > .van-icon
    font-size 14px
    padding 0 5px
    margin-top 2px
  & > ul
    white-space nowrap
    display flex
    align-items center
    font-size 14px
    color #333
    overflow hidden
    overflow-x auto
    &::-webkit-scrollbar
      display none
    & > li
      display flex
      align-items center
</style>
