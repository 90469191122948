<template>
  <div class="anjian_layout">
    <search />
    <breadcrumb @resetHistory="resetHistory" />
    <content-cell :historyCase="historyCase"
                  @resetHistory="resetHistory" />
    <global-alert />
    <Tag />
  </div>
</template>

<script>
import axios from 'axios'
import { caseBase } from '~api-config'
import Search from '../../components/Search'
import Breadcrumb from './components/Breadcrumb'
import ContentCell from './components/ContentCell'
import GlobalAlert from './components/alert'
import Tag from './components/tag'

import { mapMutations } from 'vuex'
export default {
  props: ['type'],
  components: {
    Search,
    Breadcrumb,
    ContentCell,
    GlobalAlert,
    Tag
  },
  data () {
    return {
      historyCase: {
        loading: false,
        finished: false,
        showIndex: 10
      }
    }
  },
  methods: {
    ...mapMutations('zhishishu', ['setAnjiandangan', 'setHistory', 'setCaseTag', 'setUserId']),
    resetHistory () {
      this.historyCase = {
        loading: false,
        finished: false,
        showIndex: 10
      }
    }
  },
  async created () {
    const { caseId } = this.$route.params
    const userId = this.$store.state.user.userInfo.id
    this.setUserId(userId)
    // 路由search页面
    if (this.type === 'router/path -> search') {
      const result = await axios.get(caseBase + '/lts/case/attrs?caseStatus=&pageNum=1&limit=1&keyword=')
      console.log(result.data.data.caseAttrList[0])
      const { caseId, causeName } = result.data.data.caseAttrList[0]
      // 案件当事人
      this.setCaseTag([])
      this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })

      // 默认标签
      this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })
      // const response = await this.$axios.get(`${caseBase}/new-design/modals/getCaseName?caseId=${caseId}`)
      // const { caseName } = response.data.data
      const res = await this.$axios.get(`${caseBase}/design/fileManage/cs/${caseId}?userId=${userId}`)
      const { files, litigants } = res.data.data
      const data = files.map(item => {
        return {
          ...item,
          name: item.fileName,
          types: 3
        }
      })
      this.setCaseTag(litigants)
      this.setAnjiandangan(data)
      this.setHistory({ data: { id: caseId, active: true, name: '>' + causeName, types: '2-1', userId } })
    } else {
      if (caseId === 'ios&android') {
        const response = await axios.get(`${caseBase}/farbun/other/getPerson?userId=${userId}&permissionValue=caseDoc:read`)
        const { group } = response.data.data
        const data = group.map(({ id, name, rightIcon, leftIcon, userId, permissionIds }) => {
          return {
            id: userId,
            name,
            rightIcon,
            leftIcon,
            types: 1
          }
        })
        this.setAnjiandangan(data)
        this.setCaseTag([])
        this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })
      } else {
        // 案件当事人
        this.setCaseTag([])
        this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })

        // 默认标签
        this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })
        const response = await this.$axios.get(`${caseBase}/new-design/modals/getCaseName?caseId=${caseId}`)
        const { caseName } = response.data.data
        // http://api.dev.farbun.com/design/fileManage/getCaseFolder?caseId=11704&userId=10196
        // http://api.dev.farbun.com/design/fileManage/cs/9925?userId=10235
        const res = await this.$axios.get(`${caseBase}/design/fileManage/cs/${caseId}?userId=${userId}`)
        const { files, litigants } = res.data.data
        const data = files.map(item => {
          return {
            ...item,
            name: item.fileName,
            types: 3
          }
        })
        this.setCaseTag(litigants)
        this.setAnjiandangan(data)
        // http://api.dev.farbun.com/design/fileManage/list?pId=21616
        // const data = fileModels.map(item => {
        //   return {
        //     name: item.fileName,
        //     types: 3,
        //     ...item
        //   }
        // })
        this.setHistory({ data: { id: caseId, active: true, name: '>' + caseName, types: '2-1', userId } })
      }
    }
  }
}
</script>
