<template>
  <ul class="laout_tag_container" v-show="caseTag.length !== 0">
    <li
      v-for="(item, index) of caseTag"
      :key="index"
      @click="clickHandel(item, index)"
      :style="{ background: color[index] }"
    >
      {{ item.name.substr(0, 1) }}
      <div class="show_tag" v-show="show === index">
        <van-cell title="客户案件" @click="jumpCase(item)" icon="wap-nav" />
        <van-cell title="拨打电话" icon="phone" @click="callPhone(item.phone)" />
      </div>
    </li>
  </ul>
</template>

<script>
// import { caseBase } from '~api-config'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      color: ['#1972EC', '#F3830A'],
      show: -1,
    }
  },
  computed: {
    ...mapState('zhishishu', ['caseTag']),
  },
  methods: {
    callPhone(phone) {
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // 安卓环境
          // eslint-disable-next-line
          AND2JS.call(phone)
        } else if (u === 'fb-iOS-Webview') {
          // ios环境
          // eslint-disable-next-line
          window.webkit.messageHandlers.backToTool.postMessage({ takePhoneNumber: phone })
        } else if (u === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          window.backToTool.postMessage(JSON.stringify({ takePhoneNumber: phone }))
        } else {
          this.$toast('非原生环境,返回原生指定页')
        }
      } catch (err) {
        console.log('backToTool.postMessage', err)
      }
    },
    clickHandel(item, index) {
      if (this.show !== index) {
        this.show = index
      } else {
        this.show = -1
      }
    },
    jumpCase(item) {
      if (item.customerId) {
        this.$router.push({
          name: 'Client',
          query: {
            customerId: item.customerId,
          },
        })
      } else {
        this.$router.push({
          name: 'Client',
          query: {
            litigantId: item.litigantId,
          },
        })
      }
    },
  },
}
</script>
<style lang="stylus">
.laout_tag_container
  position fixed
  bottom 0
  width 100vw
  display flex
  flex-direction row-reverse
  padding 0 20px
  z-index 1000
  & li
    height 50px
    width 50px
    background red
    border-radius 50%
    margin 5px
    line-height 50px
    text-align center
    font-size 20px
    color #fff
  & .show_tag
    position absolute
    top -60px
    padding 5px
    transform translateX(-25px)
    width 100px
    margin auto
    background #fff
    border-radius 5px
    box-shadow 0px 0 1px #40B4E9
  & .van-cell
    padding 0
    // font-size: 10px;
  & .van-cell__left-icon
    // font-size: 12px;
</style>
